import logo from './logo.svg';
import './App.css';

function App() {
	window.location.href = 'https://sites.google.com/econommi.io/leasingstart/home'  

return (
    <div className="App">
      <header className="App-header">
      </header>
    </div>
  );
}

export default App;
